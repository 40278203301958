import axios from "axios";

const apiPath = {
  development: process.env.REACT_APP_STRAPI_DEV_URL,
  production: process.env.REACT_APP_STRAPI_PRODUCTION_URL,
};

export const login = (email, password) => {
  return (dispatch) => {
    console.log(apiPath[process.env.NODE_ENV]);
    axios
      .post(apiPath[process.env.NODE_ENV] + "/auth/local", {
        identifier: email,
        password: password,
      })
      .then((response) => {
        // Handle success.
        console.log("Logged In!");
        return dispatch(setAuth("login", response.data));
      })
      .catch((error) => {
        // Handle error.
        let messages = [];
        if (
          error.response &&
          error.response.data.data.length &&
          error.response.data.data[0].messages
        ) {
          error.response.data.data[0].messages.forEach((error) => {
            messages.push({
              type: "error",
              value: error.message.replace("Identifier", "Email"),
            });
          });
        }

        return dispatch(setAuth("message", messages));
      });
  };
};

export const setMessage = (payload) => {
  return (dispatch) => {
    return dispatch(setAuth("message", payload));
  };
};

const setAuth = (type, payload) => {
  switch (type) {
    case "message":
      console.log("payload", payload);
      return {
        type: type,
        payload: payload,
      };
    case "error":
    case "login":
      return {
        type: type,
        payload: payload,
      };

    default:
      break;
  }
};
