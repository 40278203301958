import React, { Component } from "react";
import { Link } from "react-router-dom";
import headerlogo from "../../img/cwl-logo@2x.png";
import discordImg from "../../img/footer/discord.png";
import youtubeImg from "../../img/footer/youtube.png";
import facebookImg from "../../img/footer/facebook.png";
import twitterImg from "../../img/footer/twitter.png";

const socialMediaLinks = [
  {
    name: "Discord",
    url: "https://discord.gg/wjm8rn4",
    img: discordImg,
  },
  {
    name: "YouTube",
    url: "https://www.youtube.com/channel/UC5iTSIPw2criXHgKs4MZb2Q",
    img: youtubeImg,
  },
  {
    name: "Facebook",
    url: "https://www.facebook.com/classicwow.live",
    img: facebookImg,
  },
  {
    name: "Twitter",
    url: "https://twitter.com/classicwowlive",
    img: twitterImg,
  },
];

const footerLinks = [
  {
    name: "About Us",
    url: "/about",
  },
  {
    name: "Classic",
    url: "/classic/guides",
  },
  {
    name: "TBC",
    url: "/tbc/guides",
  },
  {
    name: "Leveling",
    url: "/leveling",
  },
];

const footerCopyrightLinks = [
  {
    name: "Privacy Policy",
    url: "/privacy",
  },
];

export default class Footer extends Component {
  state = {};

  render() {
    return (
      <footer className="page-footer">
        <div className="container footer-main">
          <div className="row">
            <div className="col xl4 l4 s12 footer-logo">
              <div className="footer-links">
                <ul>{this.createFooterLinks(footerLinks)}</ul>
              </div>
              <div className="valign-wrapper">
                <Link to="/">
                  <img
                    className="logo-img"
                    src={headerlogo}
                    alt="ClassicWoW.live"
                  />
                </Link>
              </div>
            </div>
            <div className="col xl3 offset-xl1 l4 m6 s12 footer-donate">
              <p>Help Us Grow</p>
              <span>
                If you want to support the team you can help us by donating. Any
                amount would help support and continue development on this
                project and is greatly appreciated.
              </span>
              <div>
                <a
                  className="waves-effect waves-light btn-small footer-btn"
                  href="https://www.paypal.me/classicwowlive"
                >
                  <i className="material-icons right">star_border</i>SUPPORT US
                </a>
              </div>
            </div>
            <div className="col xl3 offset-xl1 l4 m6 s12 footer-social">
              <p>Join The Community</p>
              <span>
                Come in and discuss new features, future goals, general problems
                or questions, or anything else you can think of.
              </span>
              <ul>{this.createSocialLinks(socialMediaLinks)}</ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            <span>
              World Of Warcraft, Warcraft And Blizzard Entertainment Are
              Trademarks Or Registered Trademarks Of Blizzard Entertainment,
              Inc. In The U.S. And/Or Other Countries
            </span>
            <ul>{this.createFooterLinks(footerCopyrightLinks)}</ul>
          </div>
        </div>
      </footer>
    );
  }
  getYear = () => {
    return new Date().getFullYear();
  };
  createFooterLinks = (links) => {
    let footerLinks = links.map(function (link) {
      return (
        <li key={link.name}>
          <a href={link.url} className="grey-text">
            {link.name}
          </a>
        </li>
      );
    });
    return footerLinks;
  };
  createSocialLinks = (links) => {
    let footerLinks = links.map(function (link) {
      return (
        <li key={link.name}>
          <a href={link.url} className="grey-text">
            <img
              className="social-img"
              src={link.img}
              height="25"
              alt={link.name}
            />
          </a>
        </li>
      );
    });
    return footerLinks;
  };
}
