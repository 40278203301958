import React, { Component } from "react";
import FreestarAdSlot from "@freestar/pubfig-adslot-react-component";

class Ads extends Component {
  state = {
    adRefresh: 0,
  };

  onAdRefresh = () => {
    const { adRefresh } = this.state;
    this.setState({
      adRefresh: adRefresh + 1,
    });
  };

  render() {
    const publisher = "classicwow-live";
    const placementName = this.props.tagId;

    return (
      <div>
      </div>
    );
  }
}

export default Ads;
