import React from "react";

const QueryError = (props) => {
  console.log("graphqlerr", JSON.parse(props.error));
  return (
    <div className="row">
      <div className="col s12">No results</div>
    </div>
  );
};

export default QueryError;
