import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import headerlogo from "../../../img/cwl-logo@2x.png";
import classic_icon from "../../../img/icons/classic_icon.svg";
import tbc_icon from "../../../img/icons/tbc_icon.svg";
import Query from "../../query";
import ARTICLES_BY_TYPE_QUERY from "../../../queries/articlesByType";
import ArticleCard from "../../articlecard";
import Footer from "../../ui/footer";
import HelmetWrapper from "../../helmet";
import Ads from "../../ads/";
import "./index.scss";

const queryprops = {
  popular: { sort: "created:desc", limit: 4, type: "guide" },
  video: { sort: "created:desc", limit: 4, type: "video" },
  podcast: { sort: "created:desc", limit: 4, type: "podcast" },
};

const helmetData = {
  title: "ClassicWoW.live",
  meta_description:
    "In depth and high quality guides, tools and content to enhance and aid you in your journeys in Azeroth during World of Warcraft Classic and World of Warcraft Classic TBC.",
};

export default class Home extends Component {
  render() {
    return (
      <Fragment>
        <HelmetWrapper params={helmetData} />
        <nav className="nav-home">
          <div className="nav-wrapper valign-wrapper">
            <Link to="/" className="brand-logo center">
              <img
                className="logo-img"
                src={headerlogo}
                height="75"
                alt="ClassicWoW.live"
              />
            </Link>
          </div>
        </nav>
        <div className="homesplash">
          <div className="home-row">
            <div className="home-col lander classic">
              <div className="banner row splash">
                <div className="desc">
                  <Link to="/classic/guides">
                    <div className="title">Classic</div>
                  </Link>
                  <div className="author home-links">
                    <Link to="/classic/guides">Guides</Link>
                    <Link to="/gearplanner">Gear Planner</Link>
                    <Link to="/leveling">Leveling</Link>
                    <Link to="/atlas">Atlas</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-col lander tbc">
              <div className="banner row splash">
                <div className="desc">
                  <Link to="/tbc/guides">
                    <div className="title">The Burning Crusade</div>
                  </Link>
                  <div className="author home-links">
                    <Link to="/tbc/guides">Guides</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container center-align">
            <Ads tagId="classicwowlive_leaderboard_atf" />
          </div>
          <section className="cwl-main-background">
            <div className="container">
              <div className="wrapper">
                <div className="home-row">
                  <div className="home-col">
                    <div className="guide-panel">
                      <h3>Newest Guides</h3>
                      <div className="row">
                        <div className="col s12 home-links">
                          <Link className="left" to="/classic/guides">
                            <span className="valign-wrapper">
                              <img
                                alt="classic"
                                title="classic"
                                height="35"
                                src={classic_icon}
                              />
                              Classic Guides
                              <i className="material-icons">launch</i>
                            </span>
                          </Link>
                          <Link className="left" to="/tbc/guides">
                            <span className="valign-wrapper">
                              <img
                                alt="tbc"
                                title="tbc"
                                height="35"
                                src={tbc_icon}
                              />
                              TBC Guides
                              <i className="material-icons">launch</i>
                            </span>
                          </Link>
                        </div>
                      </div>
                      <Query
                        query={ARTICLES_BY_TYPE_QUERY}
                        props={queryprops.popular}
                      >
                        {({ data: { articles } }) => {
                          return (
                            <div className="guide-rows">
                              {articles.map((article) => {
                                return (
                                  <ArticleCard
                                    key={article.id}
                                    props={article}
                                  />
                                );
                              })}
                            </div>
                          );
                        }}
                      </Query>
                    </div>
                    <div className="guide-panel">
                      <h3>podcasts</h3>
                      <div className="row">
                        <div className="col s12 home-links">
                          <Link className="left" to="/classic/guides">
                            <span className="valign-wrapper">
                              <img
                                alt="classic"
                                title="classic"
                                height="35"
                                src={classic_icon}
                              />
                              Good Morning Azeroth
                              <i className="material-icons">launch</i>
                            </span>
                          </Link>
                          <Link className="left" to="/tbc/guides">
                            <span className="valign-wrapper">
                              <img
                                alt="tbc"
                                title="tbc"
                                height="35"
                                src={tbc_icon}
                              />
                              Beyond the Dark Portal
                              <i className="material-icons">launch</i>
                            </span>
                          </Link>
                        </div>
                      </div>
                      <Query
                        query={ARTICLES_BY_TYPE_QUERY}
                        props={queryprops.podcast}
                      >
                        {({ data: { articles } }) => {
                          return (
                            <div className="guide-rows">
                              {articles.map((article) => {
                                return (
                                  <ArticleCard
                                    key={article.id}
                                    props={article}
                                  />
                                );
                              })}
                            </div>
                          );
                        }}
                      </Query>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
