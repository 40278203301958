import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

const cache = new InMemoryCache();
const strapiUri =
  process.env.NODE_ENV !== "development"
    ? `${process.env.REACT_APP_STRAPI_PRODUCTION_URL}/graphql`
    : `${process.env.REACT_APP_STRAPI_DEV_URL}/graphql`;
const link = new HttpLink({
  uri: strapiUri,
});

const client = new ApolloClient({
  cache,
  link,
});

export default client;
