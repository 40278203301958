import React, { Component, Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import headerlogo from "../../img/cwl-logo@2x.png";

import Login from "../pages/login";
import Register from "../pages/register";

import cookieService from "../../services/cookie";

import M from "materialize-css";
import "./style.scss";

import classic_icon from "../../img/icons/classic_icon.svg";
import tbc_icon from "../../img/icons/tbc_icon.svg";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      submitted: false,
    };
  }
  componentDidMount() {
    let elems = document.querySelectorAll(".sidenav");
    let modal = document.querySelectorAll(".login-modals");
    const options = {
      closeOnClick: true,
    };
    M.Sidenav.init(elems, options);
    M.Modal.init(modal);

    const authCookie = cookieService.getCookie("access");
    if (authCookie) {
      this.props.authProps["user"] = JSON.parse(
        cookieService.decryptCookie(authCookie)
      );
      this.props.authProps["isAuthenticated"] = true;
    }
  }
  componentDidUpdate() {
    if (this.props.authProps.isAuthenticated) {
      let elems = document.querySelectorAll("#login-modal");
      M.Modal.init(elems);
    }
  }
  render() {
    const headerLinks = {
      classic: [
        {
          name: "Guides",
          link: "/" + this.props.game + "/guides",
        },
        {
          name: "Gear Planner",
          link: "/gearplanner",
        },
        {
          name: "Atlas",
          link: "/atlas",
        },
        {
          name: "Leveling",
          link: "/leveling",
        },
      ],
      tbc: [
        {
          name: "Guides",
          link: "/" + this.props.game + "/guides",
        },
        // {
        //   name: "Arena",
        //   link: "/arena",
        // },
      ],
    };

    let headers = [];
    if (this.props.game) headers = headerLinks[this.props.game];
    let navbar_header = "cwl-header-navigation subnav classic-header";
    if (this.props.game)
      navbar_header =
        "cwl-header-navigation subnav " + this.props.game + "-header";

    return (
      <Fragment>
        <div className="cwl-headbar">
          <div className="container">
            <Link to="/" className="cwl-logo left">
              <img
                className="logo-img"
                src={headerlogo}
                height="45"
                alt="ClassicWoW.live"
              />
            </Link>
            <nav className="cwl-game-nav hide-on-med-and-down">
              <ul>
                <li>
                  <NavLink
                    to="/classic/guides"
                    activeClassName="cwl-active-navigation-link"
                    className="cwl-nav-game valign-wrapper"
                  >
                    <img
                      className="logo-img"
                      alt="classic icon"
                      src={classic_icon}
                      height="45"
                    />
                    Classic
                  </NavLink>
                </li>
                <li className="cwl-header-navigation-list-item">
                  <NavLink
                    to="/tbc/guides"
                    activeClassName="cwl-active-navigation-link"
                    className="cwl-nav-game valign-wrapper"
                  >
                    <img
                      className="logo-img"
                      alt="tbc icon"
                      src={tbc_icon}
                      height="48"
                    />
                    The Burning Crusade
                  </NavLink>
                </li>
                {/* {this.props.authProps.isAuthenticated ? (
                  <li className="cwl-header-navigation-list-item right">
                    <a
                      className="waves-effect modal-trigger"
                      href="#login-modal"
                    >
                      Logged in as: {this.props.authProps.user.username}
                    </a>
                  </li>
                ) : (
                  <li className="cwl-header-navigation-list-item right">
                    <a
                      className="waves-effect modal-trigger"
                      href="#login-modal"
                    >
                      Login
                    </a>
                    <a
                      className="waves-effect modal-trigger"
                      href="#signup-modal"
                    >
                      Signup
                    </a>
                  </li>
                )} */}
              </ul>
            </nav>
          </div>
        </div>
        <div className="cwl-header">
          <div className="navbar-fixed">
            <nav className={navbar_header}>
              <div className="container">
                <a
                  href="/"
                  data-target="mobile-demo"
                  className="sidenav-trigger right"
                >
                  <i className="material-icons">menu</i>
                </a>
                <ul className="left cwl-header-navigation-list hide-on-med-and-down">
                  <li
                    className="cwl-header-navigation-list-item"
                    key="desktop-tab-home"
                  >
                    <NavLink
                      to="/"
                      exact
                      activeClassName="cwl-active-navigation-link"
                    >
                      <i className="material-icons">home</i>
                    </NavLink>
                  </li>
                  {headers.length > 0
                    ? headers.map((data) => {
                        return (
                          <li
                            className="cwl-header-navigation-list-item"
                            key={"desktop-tab-" + data.name}
                          >
                            <NavLink
                              to={data.link}
                              activeClassName="cwl-active-navigation-link"
                            >
                              {data.name}
                            </NavLink>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </nav>
          </div>
          <ul className="sidenav" id="mobile-demo">
            <li className="cwl-header-navigation-list-item logo-list-item">
              <Link to="/" className="logo-link">
                <img
                  className="sidenav-logo-img"
                  src={headerlogo}
                  height="42"
                  alt="ClassicWoW.live"
                />
              </Link>
            </li>
            <li>
              <h5>Navigation</h5>
            </li>
            {headers.map((data) => {
              return (
                <li
                  className="cwl-header-navigation-list-item"
                  key={"mobile-tab-" + data.name}
                >
                  <NavLink
                    exact
                    to={data.link}
                    activeClassName="cwl-active-navigation-link"
                  >
                    {data.name}
                  </NavLink>
                </li>
              );
            })}
            <li>
              <h5>Account</h5>
            </li>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <AccountLinks color={"#8A5311"} /> */}
            </div>
            <li>
              <h5>Social</h5>
            </li>
            <div>{/* <OffSiteLinks isMobile={true} /> */}</div>
          </ul>
        </div>
        <div id="login-modal" className="modal login-modals">
          <div className="modal-content">
            <Login />
          </div>
        </div>
        <div id="signup-modal" className="modal login-modals">
          <div className="modal-content">
            <Register />
          </div>
        </div>
      </Fragment>
    );
  }
}
