import React, { Component } from "react";
import { connect } from "react-redux";
import { login, setMessage } from "../../../store/actions/auth";
import Messages from "../../ui/message";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, submitted: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    if (email && password) {
      this.setState({ submitted: true });
      this.props.login(email, password);
    } else {
      const errors = [];
      if (!email) {
        errors.push({
          type: "error",
          value: "Email is a required field.",
        });
      }
      if (!password) {
        errors.push({
          type: "error",
          value: "Password is a required field.",
        });
      }

      this.props.setMessage(errors);
    }
  };

  render() {
    return (
      <div className="container row">
        <h3>Login</h3>
        <div className="col s12">
          <Messages data={this.props.state.messages} />
          <div className="input-field">
            <i className="material-icons prefix">email</i>
            <input
              name="email"
              id="email_login"
              type="email"
              className="validate"
              onChange={this.handleChange}
            />
            <label htmlFor="email_login">Email</label>
          </div>
          <div className="input-field">
            <i className="material-icons prefix">vpn_key</i>
            <input
              name="password"
              id="password_login"
              type="password"
              className="validate"
              onChange={this.handleChange}
            />
            <label htmlFor="password_login">Password</label>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="input-field">
                <button
                  className={
                    "waves-effect waves-light btn-small left" +
                    (this.state.submitted ? " disabled" : "")
                  }
                  onClick={this.handleSubmit}
                >
                  Log In
                </button>
              </div>
            </div>
          </div>
          {/* <p>
            Don't have an account? <Link to="/register">Register</Link>
          </p> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state.auth,
  };
};

export default connect(mapStateToProps, { login, setMessage })(Login);
