import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Headbar from "./components/header/";
import Footer from "./components/ui/footer";
import Routes from "./routes";
import cookieService from "./services/cookie";
import Ads from "./components/ads/";

let authProps = {
  isAuthenticated: false,
  user: false,
};

// Freestar React Component Documentation: https://www.npmjs.com/package/@freestar/pubfig-adslot-react-component
// The placementName will changed based off the ad slot.
// placementName = "classicwowlive_728x90_970x90_970x250_300x250_320x50_ATF"
// placementName = "classicwowlive_300x250_300x600_160x600_RightRail"
// placementName = "classicwowlive_300x250_336x280_320x50_LeftRail"
// placementName = "classicwowlive_160x600_300x250_300x600_RightRail_Tools"
// placementName = "classicwowlive_160x600_300x250_300x600_LeftRails_Tools"
// placementName = "classicwowlive_160x600_RightRail_Forum"
// placementName = "classicwowlive_160x600_LeftRail_Forum"
// placementName = "classicwowlive_leaderboard_atf"
// placementName = "classicwowlive_leaderboard_btf"
// placementName = "classicwowlive_incontent_1"
// placementName = "classicwowlive_incontent_2"
// placementName = "classicwowlive_right_rail"

class App extends Component {
  render() {
    const authCookie = cookieService.getCookie("access");
    if (authCookie) {
      authProps["user"] = JSON.parse(cookieService.decryptCookie(authCookie));
      authProps["isAuthenticated"] = true;
    }
    const gameName = this.props.game ? this.props.game : "classic";
    return (
      <Fragment>
        <header>
          <Headbar
            isAuthenticated={authProps.isAuthenticated}
            authProps={authProps}
            game={gameName}
          />
        </header>

        <main className="cwl-content">
          <div className="container center-align adpad">
            <Ads tagId="classicwowlive_leaderboard_atf" />
          </div>
          <section className="cwl-main-background">
            <Routes authProps={authProps} game={this.props.game} />
          </section>
        </main>
        <div className="container center-align">
          <Ads tagId="classicwowlive_leaderboard_btf" />
        </div>
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state.auth,
  };
};

export default connect(mapStateToProps)(App);
