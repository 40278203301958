import React from "react";
import { Link } from "react-router-dom";
import { ellipses } from "../helpers/ellipses.js";
import classicBg from "../../img/rag.jpg";
import tbcBg from "../../img/illidan.jpg";

let imgBaseUrl = process.env.REACT_APP_BASE_PRODUCTION_URL;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  imgBaseUrl = process.env.REACT_APP_BASE_DEV_URL;
}

const removeTags = [
  // "general",
  // "pve",
  // "pvp",
  // "leveling",
  // "warrior",
  // "shaman",
  // "paladin",
  // "hunter",
  // "druid",
  // "mage",
  // "rogue",
  // "priest",
  // "warlock",
];

const ArticleCard = (article) => {
  const articleLink = ArticleLink(article);
  const articleThumb = ArticleThumb(article, imgBaseUrl);

  return (
    <div key={article.props.id}>
      <div className="card">
        <div className="card-image">
          <Link
            to={{
              pathname: articleLink,
              state: {
                game: article.props.game.name,
              },
            }}
          >
            <img
              src={articleThumb}
              alt={article.props.titleRaw}
              title={article.props.titleRaw}
            />
          </Link>
        </div>
        <div className="card-stacked">
          <div className="card-content">
            <Link
              to={{
                pathname: articleLink,
                state: {
                  game: article.props.game.name,
                },
              }}
            >
              <p>
                {!article.props.teaser
                  ? article.props.title
                  : ellipses(article.props.title, 52)}
              </p>
            </Link>
            {article.props.user ? (
              <div className="card-author">
                <Link
                  to={{
                    pathname:
                      "/user/" + article.props.user.username.toLowerCase(),
                    state: {
                      id: article.props.user.id,
                    },
                  }}
                >
                  {article.props.user.username}
                </Link>
              </div>
            ) : null}
            <div className="card-desc">{article.props.teaser}</div>
            {article.handleTagSelection ? (
              <div className="card-category">
                <div className="card-desc">
                  {ArticleTagLinks(
                    article.props.tags,
                    article.props.id,
                    article.handleTagSelection
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const ArticleLink = (article) => {
  return (
    (article.props.game.name === "tbc" ? "/" + article.props.game.name : "") +
    "/guides/" +
    article.props.id +
    "/" +
    article.props.slug +
    "?g=" +
    article.props.game.name
  );
};
const ArticleThumb = (article, imgBaseUrl) => {
  let articleThumb = null;
  if (article.props.thumbnail) {
    articleThumb =
      (article.props.thumbnail.url.includes("//") ? "" : imgBaseUrl) +
      article.props.thumbnail.url;
  } else if (article.props.thumb) {
    articleThumb = article.props.thumb;
  } else {
    if (article.props.game.name === "tbc") {
      articleThumb = tbcBg;
    } else {
      articleThumb = classicBg;
    }
  }

  return articleThumb;
};

const ArticleTagLinks = (tags, articleId, handleTagSelection) => {
  let tagLinks = [];

  let newTags = tags.filter((tag) => {
    return !removeTags.includes(tag.name.toLowerCase());
  });

  newTags.map((tag) =>
    tagLinks.push(
      <span
        onClick={() => handleTagSelection(tag.id, "secondaryTag")}
        key={tag.name + articleId}
      >
        {tag.name}
      </span>
    )
  );
  return tagLinks;
};

export default ArticleCard;
