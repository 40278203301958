import { auth } from "./auth";
// import contentReducer from "./contentReducer";
// import liveReducer from "./liveReducer";
import levelingReducer from "./levelingReducer";
import atlasReducer from "./atlasReducer";
// import guideBuilder from "./guideBuilder";
// import homeReducer from "./homeReducer";
import { combineReducers } from "redux";

const routeReducer = combineReducers({
  auth: auth,
  leveling: levelingReducer,
  atlas: atlasReducer,
});

export default routeReducer;
