import gql from "graphql-tag";

const ARTICLES_BY_TYPE_QUERY = gql`
  query ArticlesByType($sort: String!, $type: String!, $limit: Int!) {
    articles(sort: $sort, where: { type: $type }, limit: $limit) {
      id
      title
      slug
      youtube
      created
      game {
        name
      }
      type
      tags {
        id
        name
      }
      thumbnail {
        url
      }
      thumb
      user {
        id
        username
      }
      viewcount
      votes
      teaser
    }
  }
`;

export default ARTICLES_BY_TYPE_QUERY;
