import React from "react";
import { useQuery } from "@apollo/react-hooks";
import QueryError from "./error";

const Query = ({ children, query, props }) => {
  const { data, loading, error } = useQuery(query, {
    variables: props,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <QueryError error={JSON.stringify(error)} />;
  return children({ data });
};

export default Query;
