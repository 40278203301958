import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "./utils/apolloClient";
import App from "./App";
import Home from "./components/pages/home";

import "./css/main.scss";
import "./css/materialize.scss";

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ApolloProvider client={client}>
        <Switch>
          <Route path="/classic" render={() => <App game="classic" />} />
          <Route path="/tbc" render={() => <App game="tbc" />} />
          <Route path="/guides" render={() => <App />} />
          <Route path="/leveling" render={() => <App game="classic" />} />
          <Route path="/atlas" render={() => <App game="classic" />} />
          <Route path="/gearplanner" render={() => <App game="classic" />} />
          <Route path="/privacy" render={() => <App />} />
          <Route path="/user" render={() => <App />} />
          <Route render={() => <Home />} />
        </Switch>
      </ApolloProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your main to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
