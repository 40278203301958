import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "./components/asyncComponent";

const Home = asyncComponent(() => import("./components/pages/home"));
const Hardcore = asyncComponent(() => import("./components/pages/hardcore"));
const Arena = asyncComponent(() => import("./components/pages/arena"));
const Privacy = asyncComponent(() => import("./components/pages/privacy"));
const Login = asyncComponent(() => import("./components/pages/login"));
const Admin = asyncComponent(() => import("./components/pages/admin"));
const Register = asyncComponent(() => import("./components/pages/register"));
const About = asyncComponent(() => import("./components/pages/about"));
const User = asyncComponent(() => import("./components/pages/user"));
const Atlas = asyncComponent(() => import("./components/pages/atlas"));
const Tools = asyncComponent(() => import("./components/tools"));
const Guide = asyncComponent(() => import("./components/guide"));
const GuideDetail = asyncComponent(() => import("./components/guide/details/"));
// const Leveling = asyncComponent(() => import("./components/tools/leveling/"));
const Leveling = () => {
    window.location.replace('https://www.warcrafttavern.com/wow-classic/tools/leveling/');
    return null;
};
const NotFound = asyncComponent(() => import("./components/pages/notfound"));

const Routes = ({ authProps }) => (
  <Switch>
    <Route path="/" exact component={Home} props={authProps} />
    <Route path="/admin" exact component={Admin} props={authProps} />
    <Route path="/login" exact component={Login} props={authProps} />
    <Route path="/register" exact component={Register} props={authProps} />
    <Route path="/privacy" exact component={Privacy} props={authProps} />
    <Route path="/about" exact component={About} props={authProps} />
    <Route
      path="/user/:userid/:username"
      exact
      component={User}
      props={authProps}
    />
    <Route path="/atlas" exact component={Atlas} props={authProps} />
    <Route path="/gearplanner" exact component={Tools} props={authProps} />
    <Route path="/gearplanner/:id" exact component={Tools} props={authProps} />
    <Route path={"/:game/guides"} exact component={Guide} props={authProps} />
    <Route
      path={"/guides/:id/:slug"}
      exact
      component={GuideDetail}
      props={authProps}
    />
    <Route
      path={"/:game/guides/:id/:slug"}
      exact
      component={GuideDetail}
      props={authProps}
    />
    <Route
      path="/:game/hardcore"
      exact
      component={Hardcore}
      props={authProps}
    />
    <Route path="/:game/arena" exact component={Arena} props={authProps} />
    <Route path="/leveling" exact component={Leveling} props={authProps} />
    <Route
      path="/leveling/:faction/:groupSize/:race1/:class1"
      exact
      component={Leveling}
      props={authProps}
    />
    <Route
      path="/leveling/:faction/:groupSize/:race1/:class1/:race2/:class2"
      exact
      component={Leveling}
      props={authProps}
    />
    <Route
      path="/leveling/:faction/:groupSize/:race1/:class1/:race2/:class2/:race3/:class3"
      exact
      component={Leveling}
      props={authProps}
    />
    <Route path="/user/:username" exact component={User} />
    <Route exact component={NotFound} />
  </Switch>
);

export default Routes;
