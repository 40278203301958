import factionClassRace from "../../data/leveling/factionClassRace";
import guideDataMapping from "../../data/leveling/guideDataMapping";

import { createBrowserHistory } from "history";
const history = createBrowserHistory();

const initState = {
  faction: "",
  groupSize: "",
  zoneMap: "",
  level: 1,
  step: 1,
  section: 1,
  stepData: [],
  sections: [],
  steps: [],
  activeSubstep: 0,
  characterRaces: [],
  characterClasses: [],
  races: [],
  classes: [],
  edit: false,
  levelIndex: {},
  satellite: false,
};

const setHistory = (section, step, historyPathName) => {
  history.push({
    pathname: "/leveling/" + historyPathName,
    search: "?section=" + section + "&step=" + step,
  });
};

const getHistoryPath = (state) => {
  let classRaces = "";

  state.characterRaces.forEach((race, i) => {
    classRaces += "/" + race + "/" + state.characterClasses[i];
  });

  return state.faction + "/" + state.groupSize + classRaces;
};

const filterSectionDataByClass = (wowClass, wowRace, sectionData) => {
  let sectionKeys = Object.keys(sectionData);
  let returnValues = {};
  let sectionCount = 0;

  for (const key of sectionKeys) {
    let dataValues = Object.values(sectionData[key]);
    let sectionReturnValues = {};
    let stepCount = 0;

    for (const value of dataValues) {
      let classFilteredData = filterDataByClass(wowClass, wowRace, value);
      if (classFilteredData.length > 0) {
        stepCount++;
        sectionReturnValues[stepCount] = classFilteredData;
      }
    }

    sectionCount++;
    returnValues[sectionCount] = sectionReturnValues;
  }

  return returnValues;
};

const buildIndex = (levelingData) => {
  let index = {
    levels: {
      1: [1, 1],
    },
    quests: {},
  };
  let sectionKeys = Object.keys(levelingData);

  for (const section of sectionKeys) {
    let dataValues = Object.values(levelingData[section]);
    let stepCount = 1;

    for (const value of dataValues) {
      let stepWithLevelText = value.filter(
        (wat) => wat[1].indexOf("DING ") >= 0
      );
      if (stepWithLevelText.length > 0) {
        let level = parseInt(stepWithLevelText[0][1].split(" ")[1]);
        index.levels[level] = [parseInt(section, 10), stepCount];
      }

      stepCount++;
    }
  }

  return index;
};

const levelingReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_PAYLOAD":
      let levelingData = action.payload.levelingData;
      let section = action.payload.section;
      let step = action.payload.step;

      let filteredSectionData = filterSectionDataByClass(
        action.payload.characterClasses,
        action.payload.characterRaces,
        levelingData
      );

      let index = buildIndex(filteredSectionData);
      if (state.level > 1) {
        section = index.levels[state.level][0];
        step = index.levels[state.level][1];
      }

      let sectionSize = Object.keys(filteredSectionData).length;
      if (section === isNaN() || section > sectionSize || section <= 0) {
        section = 1;
      }
      let stepSize = Object.keys(filteredSectionData[section]).length;
      if (step === isNaN() || step > stepSize || step <= 0) {
        step = 1;
      }

      setHistory(
        section,
        step,
        getHistoryPath({
          characterClasses: action.payload.characterClasses,
          characterRaces: action.payload.characterRaces,
          groupSize: action.payload.groupSize,
          faction: action.payload.faction,
        })
      );

      return {
        ...state,
        stepData: filteredSectionData[section][step],
        data: levelingData,
        faction: action.payload.faction,
        characterClasses: action.payload.characterClasses,
        characterRaces: action.payload.characterRaces,
        groupSize: action.payload.groupSize,
        level: action.payload.level,
        section: section,
        step: step,
        zoneMap: getZoneMap(filteredSectionData[section][step]),
        races: setRaces(action.payload.faction),
        filteredData: filteredSectionData,
        sections: sectionSize,
        steps: stepSize,
        levelIndex: index.levels,
      };
    case "SET_DATA":
      return {
        ...state,
        faction: action.payload.faction,
        groupSize: action.payload.groupSize,
        characterRaces: action.payload.characterRaces,
        characterClasses: action.payload.characterClasses,
        level: action.payload.level,
        step: action.payload.step,
        section: action.payload.section,
      };
    case "SET_FACTION":
      return {
        ...state,
        races: setRaces(action.payload.faction),
        characterClasses: [],
        characterRaces: [],
        faction: action.payload.faction,
      };
    case "SET_SATELLITE":
      return {
        ...state,
        satellite: action.payload.satellite,
      };

    case "SET_GROUP_SIZE":
      return {
        ...state,
        groupSize: action.payload.groupSize,
      };

    case "SET_RACE":
      let characterRaces = state.characterRaces;
      let wowClasses = state.classes;
      let thisRace = action.payload.race;
      let characterIndex = parseInt(action.payload.character) - 1;

      characterRaces[characterIndex] = thisRace;
      wowClasses[characterIndex] =
        factionClassRace[state.faction].races[thisRace].classes;

      return {
        ...state,
        characterRaces: characterRaces,
        classes: wowClasses,
      };

    case "SET_CLASS":
      let characterClasses = state.characterClasses;
      characterClasses[parseInt(action.payload.character) - 1] =
        action.payload.race;

      return {
        ...state,
        characterClasses: characterClasses,
      };
    case "SET_LEVEL":
      return {
        ...state,
        level: parseInt(action.payload.level, 10),
      };

    case "SET_LEVEL_GUIDE":
      let tempSection = state.section;
      let tempStep = state.step;

      tempSection = state.levelIndex[action.payload.level][0];
      tempStep = state.levelIndex[action.payload.level][1];

      setHistory(tempSection, tempStep, getHistoryPath(state));

      return {
        ...state,
        level: action.payload.level,
        section: tempSection,
        step: tempStep,
        zoneMap: getZoneMap(state.filteredData[tempSection][tempStep]),
        stepData: state.filteredData[tempSection][tempStep],
        steps: Object.keys(state.filteredData[tempSection]).length,
        activeSubstep: 0,
      };

    case "SET_HOVER_SUBSTEP":
      return {
        ...state,
        activeSubstep: parseInt(action.payload),
      };

    case "SET_STEP":
      let newStepData =
        state.filteredData[action.payload.section][action.payload.step];

      let hasLevel = newStepData.filter((data) => {
        let tempObjective = data[1];
        return (
          tempObjective &&
          tempObjective.length > 0 &&
          tempObjective.indexOf("DING ") >= 0
        );
      });

      let tempLevel = state.level;
      if (hasLevel.length > 0) {
        tempLevel = parseInt(hasLevel[0][1].split(" ")[1]);
      }

      setHistory(
        action.payload.section,
        action.payload.step,
        getHistoryPath(state)
      );

      return {
        ...state,
        stepData: newStepData,
        section: action.payload.section,
        step: action.payload.step,
        level: tempLevel,
        zoneMap: getZoneMap(newStepData),
        sections: Object.keys(state.filteredData).length,
        steps: Object.keys(state.filteredData[action.payload.section]).length,
        activeSubstep: 0,
      };
    case "PURGE":
      return {
        ...state,
        faction: "",
        groupSize: "",
        zoneMap: "",
        level: 1,
        step: 1,
        section: 1,
        stepData: [],
        sections: [],
        steps: [],
        activeSubstep: 0,
        characterRaces: [],
        characterClasses: [],
        races: [],
        classes: [],
        edit: false,
        satellite: false,
      };
    default:
      break;
  }

  return state;
};

const setRaces = (faction) => {
  return Object.keys(factionClassRace[faction].races);
};

const filterDataByClass = (wowClasses, wowRaces, data) => {
  // Convert classes array into format in data (eg. ["priest"] becomes ["Pr"])
  let convertClasses = [];
  wowClasses.forEach((element) => {
    convertClasses.push(guideDataMapping.classes[element]);
  });

  // Convert races array into format in data (eg. ["mage"] becomes ["M"])
  let convertedRaces = [];
  wowRaces.forEach((element) => {
    convertedRaces.push(guideDataMapping.races[element]);
  });

  let filteredByClass = data.filter((subStep, i) => {
    let classValue = subStep[3];
    let raceValue = subStep[2];

    let tempRaces = convertedRaces.filter((race) => {
      return raceValue.includes(race);
    });
    let tempClasses = convertClasses.filter((wowClasses) => {
      return classValue.includes(wowClasses);
    });

    let classTruthy = tempClasses.length > 0 ? true : false;
    let raceTruthy = tempRaces.length > 0 ? true : false;

    return (
      (raceTruthy && classTruthy) ||
      (raceValue === "" && classTruthy) ||
      (classValue === "" && raceTruthy) ||
      (classValue === "" && raceValue === "")
    );
  });

  return filteredByClass ? filteredByClass : [];
};

const getZoneMap = (data) => {
  return data[0][13];
};

export default levelingReducer;
