import React from "react";

const Messages = (params) => {
  let messages = [];
  params.data.forEach((message, i) => {
    messages.push(
      <div
        className={
          "card-panel lighten-2 " + (message.type === "error" ? "red" : "green")
        }
        key={"message_" + i}
      >
        {message.value}
      </div>
    );
  });
  return messages;
};

export default Messages;
