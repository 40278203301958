import React, { Component } from "react";

class Register extends Component {
  render() {
    return (
      <div className="container row">
        <div className="col s12">
          <h3>Register</h3>
          <div className="input-field ">
            <i className="material-icons prefix">account_circle</i>
            <input id="username" type="text" className="validate" />
            <label htmlFor="username">Choose Username</label>
            <span className="helper-text">Usernames must be unique.</span>
          </div>
          <div className="input-field ">
            <i className="material-icons prefix">email</i>
            <input id="email" type="email" className="validate" />
            <label htmlFor="email">Enter Email</label>
            <span className="helper-text">
              By default, your email will be hidden from the public.
            </span>
          </div>
          <div className="input-field">
            <i className="material-icons prefix">vpn_key</i>
            <input id="password1" type="password" className="validate" />
            <label htmlFor="password1">Enter Password</label>
            <span className="helper-text">
              Your password's length must be at least 6 characters.
            </span>
          </div>
          <div className="input-field">
            <i className="material-icons prefix">vpn_key</i>
            <input id="password2" type="password" className="validate" />
            <label htmlFor="password2">Confirm Password</label>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="input-field">
                <button className="waves-effect waves-light btn-small left">
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
