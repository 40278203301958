import { formatName } from "../../components/helpers/formatName";
import atlasData from "../../data/atlas.json";
import atlasBlurbs from "../../data/atlasBlurbs.json";

const initState = {
  hoveredArea: null,
  mapKey: "",
  mapRaw: "",
  mapUrl: "",
  mapImgMap: {},
  mapWidth: "",
  mapHeight: "",
  mapImgWidth: "",
  mapBlurbs: [],
  mapSource: "",
  breadCrumb: ["Azeroth"],
  mapView: "map",
};

const zones = [
  "Dun Morogh",
  "Durotar",
  "Elwynn Forest",
  "Mulgore",
  "Teldrassil",
  "Darkshore",
  "Loch Modan",
  "Westfall",
  "The Barrens",
  "Redridge Mountains",
  "Stonetalon Mountains",
  "Ashenvale",
  "Duskwood",
  "Wetlands",
  "Thousand Needles",
  "Desolace",
  "Stranglethorn Vale",
  "Dustwallow Marsh",
  "Badlands",
  "Swamp of Sorrows",
  "Feralas",
  "Tanaris",
  "Searing Gorge",
  "Azshara",
  "Blasted Lands",
  "Un'goro Crater",
  "Felwood",
  "Burning Steppes",
  "Winterspring",
  "Deadwind Pass",
  "Moonglade",
  "Silithus",
  "Eastern Plaguelands",
  "Western Plaguelands",
  "Tirisfal Glades",
  "Silverpine Forest",
  "Hillsbrad Foothills",
  "The Hinterlands",
  "Arathi Highlands",
  "Alterac Mountains",
];

const urlBase = "https://api.classicwow.live/cwl-atlas/maps/";

const getMapURL = (mapKey, mapView, mapSatellite) => {
  return (
    urlBase +
    mapKey +
    (mapView === "satellite" && mapSatellite ? "-satellite" : "") +
    ".jpg"
  );
};

const atlasReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_MAPVIEW":
      let mapView = action.payload;
      let mapUrl = getMapURL(state.mapKey, mapView, true);

      return {
        ...state,
        mapUrl: mapUrl,
        mapView: mapView,
      };
    case "SET_AREA":
      return {
        ...state,
        hoveredArea: action.payload,
      };
    case "SET_MAP":
      let tempPayload = action.payload;
      if (tempPayload === "zoom-out") {
        tempPayload = getZoomOutMapKey(state.breadCrumb);
      }
      const mapKey = formatName(tempPayload);

      const mapImgMap = atlasData.data[mapKey] ? atlasData.data[mapKey] : [];
      let mapWidth =
        atlasData.maps[mapKey] && atlasData.maps[mapKey].width
          ? atlasData.maps[mapKey].width
          : 1132;
      const mapHeight =
        atlasData.maps[mapKey] && atlasData.maps[mapKey].height
          ? atlasData.maps[mapKey].height
          : 755;
      let mapImgWidth =
        atlasData.maps[mapKey] && atlasData.maps[mapKey].imgWidth
          ? atlasData.maps[mapKey].imgWidth
          : 1878;
      const mapBlurbs =
        atlasBlurbs[mapKey] && atlasBlurbs[mapKey].blurb
          ? atlasBlurbs[mapKey].blurb
          : "";
      const mapSource =
        atlasBlurbs[mapKey] && atlasBlurbs[mapKey].source
          ? atlasBlurbs[mapKey].source
          : "";
      const mapSatellite =
        atlasData.maps[mapKey] && atlasData.maps[mapKey].urlSatellite
          ? true
          : false;

      // New maps without blurbs have different sizes, resize
      if (!mapBlurbs) {
        mapImgWidth = 2560;
        mapWidth = 1342;
      }
      // Set breadcrumb for map header and navigation
      const mapBreadCrumb = setBreadCrumb(
        tempPayload,
        state.breadCrumb,
        mapSatellite
      );

      return {
        ...state,
        hoveredArea: null,
        mapUrl: getMapURL(mapKey, state.mapView, mapSatellite),
        mapImgMap: mapImgMap,
        mapKey: mapKey,
        mapWidth: mapWidth,
        mapHeight: mapHeight,
        mapImgWidth: mapImgWidth,
        mapRaw: tempPayload,
        mapBlurbs: mapBlurbs,
        mapSource: mapSource,
        breadCrumb: mapBreadCrumb,
        mapSatellite: mapSatellite,
      };
    default:
      break;
  }
  return state;
};

const getZoomOutMapKey = (breadCrumb) => {
  let returnMapKey = "Azeroth";
  if (breadCrumb.length > 1) {
    returnMapKey = breadCrumb[breadCrumb.length - 2];
  }
  return returnMapKey;
};

const setBreadCrumb = (crumb, breadcrumb) => {
  if (breadcrumb.includes(crumb)) {
    let newBreadCrumb = [];
    for (var i = 0; i < breadcrumb.length; i++) {
      newBreadCrumb.push(breadcrumb[i]);
      if (breadcrumb[i] === crumb) {
        breadcrumb = newBreadCrumb;
      }
    }
  } else {
    if (breadcrumb.length === 3 && zones.includes(crumb)) {
      breadcrumb[2] = crumb;
    } else {
      breadcrumb.push(crumb);
    }
  }
  return breadcrumb;
};

export default atlasReducer;
