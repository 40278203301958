import React from "react";
import { Helmet } from "react-helmet";

const HelmetWrapper = ({ params }) => {
  return (
    <Helmet defaultTitle="ClassicWoW.live" titleTemplate="%s">
      {params && params.title ? <title>{params.title}</title> : null}
      {params && params.meta_description ? (
        <meta name="description" content={params.meta_description} />
      ) : null}
      {params && params.canonical ? (
        <link rel="canonical" href={params.canonical} />
      ) : null}
    </Helmet>
  );
};

export default HelmetWrapper;
