import cookieService from "../../services/cookie";

//let user = JSON.parse(localStorage.getItem("user"));
//const initialState = user ? { loggedIn: true, user } : {};

const initState = {
  isAuthenticated: false,
  messages: [],
  user: {},
};

export function auth(state = initState, action) {
  switch (action.type) {
    case "login":
      let user = {
        jwt: action.payload.jwt,
        email: action.payload.user.email,
        role: action.payload.user.role.type,
        username: action.payload.user.username,
        id: action.payload.user.id,
      };

      let encrypt = cookieService.encryptCookie(JSON.stringify(user));
      cookieService.setCookie("access", encrypt);

      return {
        ...state,
        user: user,
        isAuthenticated: true,
      };
    case "message":
      return {
        ...state,
        messages: action.payload,
      };
    default:
      break;
  }
  return state;
}
