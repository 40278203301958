import Cookies from "universal-cookie";
const crypto = require("crypto");
const cookies = new Cookies();

const cwlCookies = {
  consent: "_cwlCookieConsent",
  access: "_cwlAccessToken",
};
const cryptoKey = "vanillaismyfavoriteflavor";

const cookieService = {
  getCookie: (cookieKey) => {
    return cookies.get(cwlCookies[cookieKey]);
  },
  setCookie: (cookieKey, data) => {
    cookies.set(cwlCookies[cookieKey], data, { path: "/" });
  },
  removeCookie: (cookieKey) => {
    cookies.remove(cwlCookies[cookieKey], { path: "/" });
  },
  encryptCookie: (data) => {
    let cipher = crypto.createCipher("aes-256-ctr", cryptoKey);
    return cipher.update(data, "utf8", "hex") + cipher.final("hex");
  },
  decryptCookie: (data) => {
    try {
      var cipher = crypto.createDecipher("aes-256-ctr", cryptoKey);
      return cipher.update(data, "hex", "utf8") + cipher.final("utf8");
    } catch (error) {
      return false;
    }
  },
};

export default cookieService;
